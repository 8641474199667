<template>
  <div style="width: auto; height: 100%" id="myChart2"></div>
</template>

<script>
import * as echarts from "echarts";
import { GetrecordTrend } from "@/request/api";
export default {
  name: "HtglEcharts2",

  data() {
    return {};
  },

  mounted() {
    this.echartsInit();
  },

  methods: {
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      GetrecordTrend().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$echarts.init(document.getElementById("myChart2")).setOption({
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: ["执法人员数"],
            },
            xAxis: {
              type: "category",
              data: res.data.xdata,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: res.data.ydata,
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(220, 220, 220, 0.5)",
                },
                name: "执法人员数",
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>