<template>
  <div style="width: auto; height: 100%" id="myChart1"></div>
</template>

<script>
import * as echarts from "echarts";
import { GetscanQrCodeTrend } from "@/request/api";
export default {
  name: "HtglEcharts1",

  data() {
    return {};
  },

  mounted() {
    this.echartsInit();
  },

  methods: {
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      GetscanQrCodeTrend().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$echarts.init(document.getElementById("myChart1")).setOption({
            tooltip: {
              trigger: "axis",
            },
            legend: {
              data: ["企业扫码数"],
            },
            xAxis: {
              type: "category",
              data: res.data.xdata,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: res.data.ydata,
                type: "line",
                name: "企业扫码数",
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>