<template>
  <div>
    <div class="head">
      <div class="block">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="timechange(value1)"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <!-- 选择单位 -->
        <el-select
          v-model="value2"
          filterable
          placeholder="请选择单位"
          @change="search1(value2)"
        >
          <el-option
            v-for="item in options1"
            :key="item.id"
            :label="item.companyName"
            :value="item.companyName"
          >
          </el-option>
        </el-select>
        <!-- 选择处罚类型 -->
        <el-select
          v-model="value3"
          filterable
          placeholder="请选择处罚类型"
          @change="search2(value3)"
        >
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.dictLabel"
            :value="item.dictLabel"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 表格 sortable-->
    <el-table
      :data="tableData"
      style="width: 100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
      border
    >
      <el-table-column prop="approvalDate" label="审批时间" sortable>
      </el-table-column>
      <el-table-column prop="approvalName" label="审批人"> </el-table-column>
      <el-table-column prop="approvalResult" label="结果"> </el-table-column>
      <el-table-column prop="companyName" label="公司名称"> </el-table-column>
      <el-table-column prop="content" label="执法内容"> </el-table-column>
      <el-table-column prop="deptName" label="执法部门"> </el-table-column>
      <el-table-column prop="lawType" label="处罚类型"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  GetsysLawEnforcementRecord,
  GetsysDictTypelistData,
  GetumsCompany,
} from "@/request/api";
export default {
  name: "HtglHometable1",

  data() {
    return {
      tableData: [],
      value1: "", //日期选择器
      options1: [],
      value2: "", //选中的单位
      options2: [],
      value3: "", //选中的处罚类型
      startDate: "", //开始时间
      endDate: "", //结束时间
      companyName: "", //企业名称
      lawType: "", //执法类型
    };
  },

  methods: {
    timechange(v) {
      // console.log(v, 123);
      this.startDate = v[0];
      this.endDate = v[1];
      this.seelist();
    },
    // 选择单位
    search1(v) {
      // console.log(v);
      this.companyName = v;
      this.seelist();
    },
    search2(v) {
      // console.log(v);
      this.lawType = v;
      this.seelist();
    },
    // 企业名称
    seelist2() {
      GetumsCompany({
        pageNum: 1,
        pageSize: 1000000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options1 = res.data.list;
        }
      });
    },
    // 执法类型列表
    seelist3() {
      GetsysDictTypelistData({
        dictSort: 0,
        dictType: "执法",
        pageNum: 1,
        pageSize: 100000000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options2 = res.data.list;
        }
      });
    },
    // 列表
    seelist() {
      GetsysLawEnforcementRecord({
        companyName: this.companyName,
        endDate: this.endDate,
        startDate: this.startDate,
        keyword: "",
        lawType: this.lawType,
        pageNum: 1,
        pageSize: 100000000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.seelist();
    this.seelist2();
    this.seelist3();
  },
};
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  margin-bottom: 20px;
}
</style>