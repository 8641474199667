<template>
  <div style="width: 100%; height: 100%">
    <el-date-picker
      v-model="value1"
      type="daterange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="timechange(value1)"
      value-format="yyyy-MM-dd"
    >
    </el-date-picker>
    <el-select v-model="value" placeholder="排序方式" style="margin-left: 10px">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <div style="width: auto; height: 95%" id="myChart5"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { GetlawEnforcementTrend } from "@/request/api";
export default {
  name: "HtglEcharts5",

  data() {
    return {
      startDate: "", //开始时间
      endDate: "", //结束时间
      value1: "",
      options: [
        {
          value: "执法次数",
          label: "执法次数",
        },
      ],
      value: "",
    };
  },

  mounted() {
    this.echartsInit();
  },

  methods: {
    timechange(v) {
      // console.log(v);
      this.startDate = v[0];
      this.endDate = v[1];
      // console.log(String(this.endDate));
      this.echartsInit2();
    },
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      GetlawEnforcementTrend().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$echarts.init(document.getElementById("myChart5")).setOption({
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: ["执法次数"],
            },
            xAxis: {
              type: "category",
              data: res.data.xdata,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: res.data.ydata,
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(220, 220, 220, 0.5)",
                },
                // itemStyle: {
                //   color: "red",
                // },
                name: "执法次数",
              },
            ],
          });
        }
      });
    },
    echartsInit2() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      GetlawEnforcementTrend({
        startDate: this.startDate,
        endDate: this.endDate,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$echarts.init(document.getElementById("myChart5")).setOption({
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              data: ["执法次数"],
            },
            xAxis: {
              type: "category",
              data: res.data.xdata,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: res.data.ydata,
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(220, 220, 220, 0.5)",
                },
                // itemStyle: {
                //   color: "red",
                // },
                name: "执法次数",
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>