<template>
  <div class="table">
    <div class="head">
      <el-input
        placeholder="请输入内容"
        v-model="input"
        class="input-with-select"
        style="width: 250px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search()"
        ></el-button>
      </el-input>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加
      </el-button>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="daochu()"
      >
        导出模板
      </el-button>
      <el-upload
        class="upload-demo"
        action="https://www.hyzfjd.cn/api/admin/importUser"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-success="correct"
        multiple
        :limit="999"
        :on-exceed="handleExceed"
        :file-list="fileList"
        :headers="headers"
        :show-file-list="false"
      >
        <el-button style="margin-left: 10px" type="primary"
          >批量导入执法人员</el-button
        >
      </el-upload>
    </div>
    <div class="tablebox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="nickName" label="姓名"> </el-table-column>
        <el-table-column prop="username" label="手机号码"> </el-table-column>
        <el-table-column prop="personType" label="人员类型">
          <template slot-scope="scope">
            {{
              scope.row.personType == 1
                ? "管理员"
                : scope.row.personType == 2
                ? "执法人员"
                : scope.row.personType == 3
                ? "企业"
                : scope.row.personType == 4
                ? "乡镇"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="roleName" label="角色"> </el-table-column>
        <el-table-column prop="orgName" label="部门"> </el-table-column>
        <el-table-column label="操作" width="430px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              v-if="qx == '管理员'"
            >
              删除
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="handlepass(scope.row)"
            >
              修改密码
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="handleresetpass(scope.row)"
              v-if="qx == '管理员'"
            >
              重置密码
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="handleresetuser(scope.row)"
              v-if="qx == '管理员'"
            >
              修改手机号
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <!-- <el-form-item label="日期">
          <el-input v-model="formLabelAlign.date"></el-input>
        </el-form-item> -->
        <el-form-item label="姓名">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="电子邮箱">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="用户名">
          <el-input v-model="formLabelAlign.username"></el-input>
        </el-form-item> -->
        <el-form-item label="选择人员类型">
          <el-select v-model="value3" filterable placeholder="请选择">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择角色">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addrole(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--  -->
        <el-form-item label="选择部门">
          <el-select
            v-model="value2"
            filterable
            placeholder="请选择"
            :disabled="bool1"
            @change="adddetup(value2)"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.deptName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择企业">
          <el-select
            v-model="value4"
            filterable
            placeholder="请选择"
            :disabled="bool2"
            @change="adddetup2(value4)"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择乡镇">
          <el-select
            v-model="value5"
            filterable
            placeholder="请选择"
            :disabled="bool3"
            @change="adddetup3(value5)"
          >
            <el-option
              v-for="item in options5"
              :key="item.id"
              :label="item.countryName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗2 -->
    <el-dialog
      title="新建"
      :visible.sync="dialogVisible2"
      width="50%"
      :show-close="false"
    >
      <!-- 添加表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <el-form-item label="姓名">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="电子邮箱">
          <el-input v-model="formLabelAlign.type"></el-input>
        </el-form-item> -->
        <el-form-item label="手机号">
          <el-input v-model="formLabelAlign.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="formLabelAlign.password"></el-input>
        </el-form-item>
        <el-form-item label="选择人员类型">
          <el-select v-model="value3" filterable placeholder="请选择">
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择角色">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="addrole(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--  -->
        <el-form-item label="选择部门">
          <el-select
            v-model="value2"
            filterable
            placeholder="请选择"
            @change="adddetup(value2)"
            :disabled="bool1"
          >
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.deptName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择企业">
          <el-select
            v-model="value4"
            filterable
            placeholder="请选择"
            :disabled="bool2"
            @change="adddetup2(value4)"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择乡镇">
          <el-select
            v-model="value5"
            filterable
            placeholder="请选择"
            :disabled="bool3"
            @change="adddetup3(value5)"
          >
            <el-option
              v-for="item in options5"
              :key="item.id"
              :label="item.countryName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm2()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 更改密码弹窗 -->
    <el-dialog title="更改密码" :visible.sync="dialogVisible3" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign2"
      >
        <el-form-item label="手机号码">
          <el-input v-model="formLabelAlign2.username"></el-input>
        </el-form-item>
        <el-form-item label="旧密码">
          <el-input v-model="formLabelAlign2.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="formLabelAlign2.newPassword"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="confirm3()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 更改手机号弹窗 -->
    <el-dialog title="更改手机号" :visible.sync="dialogVisible4" width="50%">
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign3"
      >
        <el-form-item label="手机号码">
          <el-input v-model="formLabelAlign3.username"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="confirm4()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  Getadminlist,
  Postupdate,
  Postdelete,
  Postregister,
  PostsysDept,
  PostupdatePassword,
  Postroleupdate,
  Postroleid,
  PostrolelistAll,
  GetsysDept,
  GetumsCompany,
  GetadminexportTemplate,
  PostadminimportUser,
  getsysCountrylist,
  Getadmininfo,
  PostresetPassword,
} from "@/request/api";
var token = localStorage.getItem("token");
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容 HtglEnterprisetable4
      tableData: [],
      dialogVisible: false, //弹出框判定
      dialogVisible2: false, //弹出框判定
      dialogVisible3: false, //弹出框判定
      dialogVisible4: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        name: "",
        type: "",
        username: "",
        password: "",
      },
      formLabelAlign2: {
        newPassword: "",
        oldPassword: "",
        username: "",
      },
      formLabelAlign3: {
        username: "",
      },
      currentPage: 1, //分页
      total: 1,
      pageNum: 1,
      pageSize: 10,
      id: "",
      admin: {}, //修改向后台传的参数,
      keyword: "", //搜索条件,
      //角色数据
      options: [],
      value: "",
      valuename: "",
      //部门数据
      options2: [],
      value2: "",
      valuename2: "",
      // 人员类型
      value3: "",
      options3: [
        {
          value: 1,
          label: "管理员",
        },
        {
          value: 2,
          label: "执法人员",
        },
        {
          value: 3,
          label: "企业",
        },
        {
          value: 4,
          label: "乡镇",
        },
      ],
      options4: [],
      value4: "",
      valuename4: "",
      options5: [],
      value5: "",
      valuename5: "",
      bool1: false,
      bool2: true,
      bool3: true,
      headers: { Authorization: token },
      fileList: [],
      qx: "",
      info4: "",
    };
  },

  methods: {
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    correct(file, fileList) {
      // console.log(file, fileList);
      this.seelist();
      this.$message({
        message: "上传成功",
        type: "success",
      });
    },
    // 导出模板
    daochu() {
      GetadminexportTemplate().then((res) => {
        const url = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(url);
        a.download = "执法人员模板.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    daoru() {
      PostadminimportUser;
    },
    // 修改
    handleUpdate(v) {
      console.log(v);
      this.value = v.roleId;
      if (this.value == 8) {
        this.bool1 = true;
        this.bool2 = false;
        this.bool3 = true;
        this.value4 = v.orgId;
        this.valuename4 = v.orgName;
      } else if (this.value == 10) {
        this.bool1 = true;
        this.bool2 = true;
        this.bool3 = false;
        this.value5 = v.orgId;
        this.valuename5 = v.orgName;
      } else {
        this.bool1 = false;
        this.bool2 = true;
        this.bool3 = true;
        this.value2 = v.orgId;
        this.valuename2 = v.orgName;
      }
      this.valuename = v.roleName;
      this.value3 = v.personType;
      this.id = v.id;
      this.formLabelAlign.name = v.nickName;
      this.formLabelAlign.type = v.email;
      this.formLabelAlign.username = v.username;
      this.admin = {
        createTime: this.formLabelAlign.date,
        email: this.formLabelAlign.type,
        icon: v.icon,
        id: v.id,
        loginTime: v.loginTime,
        nickName: this.formLabelAlign.name,
        note: v.note,
        password: "",
        status: v.status,
        username: this.formLabelAlign.username,
        personType: v.personType,
        roleId: v.roleId,
        roleName: v.roleName,
        orgId: v.orgId,
        orgName: v.orgName,
      };

      this.dialogVisible = true;
    },
    // 弹窗确定
    confirm() {
      this.admin.createTime = this.formLabelAlign.date;
      this.admin.email = this.formLabelAlign.type;
      this.admin.nickName = this.formLabelAlign.name;
      this.admin.roleId = this.value;
      this.admin.roleName = this.valuename;
      if (this.value == 8) {
        this.admin.orgId = this.value4;
        this.admin.orgName = this.valuename4;
      } else if (this.value == 10) {
        this.admin.orgId = this.value5;
        this.admin.orgName = this.valuename5;
      } else {
        this.admin.orgId = this.value2;
        this.admin.orgName = this.valuename2;
      }

      this.admin.personType = this.value3;
      // console.log(this.admin);
      Postupdate(this.admin).then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.seelist();
          this.dialogVisible = false;
          this.admin = "";
        } else {
          this.$message.error(res.message);
        }
      });
      // PostupdatePassword({
      //   username: this.formLabelAlign.username,
      // }).then((res) => {
      //   console.log(res);
      // });
    },
    // 删除
    handleDelete(v) {
      this.id = v.id;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Postdelete(this.id).then((res) => {
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 搜索
    search() {
      this.keyword = this.input;
      this.seelist();
      this.keyword = "";
    },
    // 修改密码
    handlepass(v) {
      // console.log(v);
      this.formLabelAlign2.username = v.username;
      this.formLabelAlign2.oldPassword = "";
      this.formLabelAlign2.newPassword = "";
      this.dialogVisible3 = true;
    },
    confirm3() {
      PostupdatePassword({
        username: this.formLabelAlign2.username,
        oldPassword: this.formLabelAlign2.oldPassword,
        newPassword: this.formLabelAlign2.newPassword,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "更改成功",
            type: "success",
          });
          this.dialogVisible3 = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "error",
          });
        }
      });
    },
    // 修改手机号
    handleresetuser(v) {
      // console.log(v);
      this.info4 = v;
      this.formLabelAlign3.username = v.username;
      this.dialogVisible4 = true;
    },
    confirm4() {
      Postupdate({
        createTime: this.info4.createTime,
        email: this.info4.email,
        icon: this.info4.icon,
        id: this.info4.id,
        loginTime: this.info4.loginTime,
        nickName: this.info4.nickName,
        note: this.info4.note,
        orgId: this.info4.orgId,
        orgName: this.info4.orgName,
        password: this.info4.password,
        personType: this.info4.personType,
        roleId: this.info4.roleId,
        roleName: this.info4.roleName,
        status: this.info4.status,
        username: this.formLabelAlign3.username,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: "更改成功",
            type: "success",
          });
          this.seelist();
          this.dialogVisible4 = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "error",
          });
        }
      });
    },

    // 重置密码
    handleresetpass(v) {
      // console.log(v.username);
      this.$confirm("此操作将重置该账号密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          PostresetPassword({ username: v.username }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              this.seelist();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    //添加数据
    clickadd() {
      this.admin = "";
      this.formLabelAlign.name = "";
      this.formLabelAlign.type = "";
      this.formLabelAlign.username = "";
      this.formLabelAlign.password = "";
      this.value = "";
      this.value4 = "";
      this.value5 = "";
      this.value2 = "";
      this.valuename = "";
      this.valuename2 = "";
      this.valuename5 = "";
      this.dialogVisible2 = true;
    },
    confirm2() {
      // console.log(this.value);
      if (this.valuename == "企业") {
        this.admin = {
          email: this.formLabelAlign.type,
          icon: "string",
          nickName: this.formLabelAlign.name,
          note: "string",
          orgId: this.value4,
          orgName: this.valuename4,
          password: this.formLabelAlign.password,
          personType: this.value3,
          roleId: this.value,
          roleName: this.valuename,
          username: this.formLabelAlign.username,
        };
      } else if (this.valuename == "乡镇") {
        this.admin = {
          email: this.formLabelAlign.type,
          icon: "string",
          nickName: this.formLabelAlign.name,
          note: "string",
          orgId: this.value5,
          orgName: this.valuename5,
          password: this.formLabelAlign.password,
          personType: this.value3,
          roleId: this.value,
          roleName: this.valuename,
          username: this.formLabelAlign.username,
        };
      } else {
        this.admin = {
          email: this.formLabelAlign.type,
          icon: "string",
          nickName: this.formLabelAlign.name,
          note: "string",
          orgId: this.value2,
          orgName: this.valuename2,
          password: this.formLabelAlign.password,
          personType: this.value3,
          roleId: this.value,
          roleName: this.valuename,
          username: this.formLabelAlign.username,
        };
      }

      Postregister(this.admin).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible2 = false;
          this.admin = "";
          this.value2 = "";
          this.value4 = "";
          this.valuename2 = "";
          this.valuename4 = "";
        } else if (res.code == 500) {
          this.$message.error("手机号重复");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择角色
    addrole(v) {
      // console.log(v);
      this.options.map((item, index) => {
        if (item.id == v) {
          // console.log(item.name);
          this.valuename = item.name;
          if (item.name == "企业") {
            this.bool1 = true;
            this.bool2 = false;
            this.bool3 = true;
          } else if (item.name == "乡镇") {
            this.bool1 = true;
            this.bool2 = true;
            this.bool3 = false;
          } else {
            this.bool1 = false;
            this.bool2 = true;
            this.bool3 = true;
          }
        }
      });
    },
    // 选择部门
    adddetup(v) {
      // console.log(v);
      this.options2.map((item, index) => {
        if (item.id == v) {
          // console.log(item.deptName);
          this.valuename2 = item.deptName;
        }
      });
    },
    adddetup2(v) {
      this.options4.map((item, index) => {
        if (item.id == v) {
          // console.log(item.companyName);
          this.valuename4 = item.companyName;
        }
      });
    },
    adddetup3(v) {
      this.options5.map((item, index) => {
        if (item.id == v) {
          // console.log(item.countryName);
          this.valuename5 = item.countryName;
        }
      });
    },
    // 查看所有角色
    listall() {
      PostrolelistAll().then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.options = res.data;
          this.list1 = res.data;
        }
      });
    },
    // 查看所有部门
    seedep() {
      GetsysDept({
        deptName: "",
        pageNum: 1,
        pageSize: 10000000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options2 = res.data.list;
        }
      });
      GetumsCompany({
        companyName: "",
        country: "",
        pageNum: 1,
        pageSize: 10000000,
      }).then((res) => {
        if (res.code == 200) {
          this.options4 = res.data.list;
        }
      });
      getsysCountrylist({
        companyName: "",
        pageNum: 1,
        pageSize: 10000000,
      }).then((res) => {
        if (res.code == 200) {
          this.options5 = res.data.list;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    quanxian() {
      Getadmininfo().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.qx = res.data.roles[0];
        }
      });
    },
    // 查看人员列表
    seelist() {
      Getadminlist({
        keyword: this.keyword,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res.data);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.data.totalPage;
          this.total = res.data.total;
          // console.log(this.currentPage);
        }
      });
    },
  },
  mounted() {
    this.seelist(); //人员列表
    this.listall(); //角色列表
    this.seedep(); //部门列表
    this.quanxian();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>