<template>
  <div class="table">
    <div class="head">
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加部门
      </el-button>
    </div>
    <div class="tablebox">
      <el-table
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        border
      >
        <el-table-column prop="deptName" label="部门名称"> </el-table-column>
        <el-table-column
          prop="createTime"
          label="录入时间"
          sortable
        >
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="primary"
              size="mini"
              @click="handleenter(scope.row)"
            >
              分管企业
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="tableData.length"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form :label-position="labelPosition" label-width="120px">
        <el-form-item label="部门名称">
          <el-input v-model="username" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人">
          <el-select v-model="valuename" filterable placeholder="请选择">
            <el-option
              v-for="item in optionsname"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分管副局长">
          <el-select v-model="valuename2" filterable placeholder="请选择">
            <el-option
              v-for="item in optionsname2"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="执法人员">
          <el-input
            v-model="username"
            :disabled="true"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择部门">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选部门">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item.name }}
          </el-tag>
        </el-form-item> -->
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog
      title="新建部门"
      :visible.sync="dialogVisible2"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 新建表单 -->
      <el-form :label-position="labelPosition" label-width="80px">
        <el-form-item label="部门名称">
          <el-input v-model="username" style="width: 200px"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm2()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 归属企业弹窗 -->
    <el-dialog
      title="归属企业"
      :visible.sync="dialogVisible3"
      width="60%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form :label-position="labelPosition" label-width="120px">
        <el-form-item label="添加归属企业">
          <el-select v-model="value" filterable placeholder="请选择添加企业">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="primary"
            @click="addseelist()"
            style="margin-left: 10px"
            >添加</el-button
          >
          <el-button
            type="danger"
            @click="delseelist()"
            style="margin-left: 10px"
            >移出选中企业</el-button
          >
        </el-form-item>
        <!-- 部门对应企业表单 -->
        <el-table
          :data="optionents"
          style="width: 100%"
          border
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="companyName" label="企业名称">
          </el-table-column>
          <el-table-column prop="companyCode" label="企业编码">
          </el-table-column>
          <el-table-column prop="country" label="所属乡"> </el-table-column>
          <el-table-column prop="companyLabel" label="企业标签">
          </el-table-column>
          <el-table-column prop="companyContacts" label="企业联系人">
          </el-table-column>
          <el-table-column prop="companyContactsPhone" label="联系人电话">
          </el-table-column>
        </el-table>
        <!-- <el-form-item label="执法人员">
          <el-input
            v-model="username"
            :disabled="true"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择部门">
          <el-select
            v-model="value"
            filterable
            placeholder="请选择"
            @change="add(value)"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="已选部门">
          <el-tag
            v-for="(item, index) in valuelist"
            :key="index"
            closable
            @close="tagdel(index)"
            style="margin-right: 10px"
          >
            {{ item.name }}
          </el-tag>
        </el-form-item> -->
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysDept,
  PostsysDept,
  DeletesysDept,
  PutsysDeptupdate,
  getCompanyByDeptId,
  GetumsCompany,
  PostsaveRelation,
  PostremoveRelation,
  Getadminlist,
  GetuserList,
} from "@/request/api";
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容
      tableData: [], //部门列表
      dialogVisible: false, //编辑弹出框判定
      dialogVisible2: false, //新建弹出框判定
      dialogVisible3: false, //归属企业弹出框判定
      username: "",
      labelPosition: "left",
      valuename: "",
      valuename2: "",
      optionsname: "",
      optionsname2: "",
      //公司数据
      options: [],
      value: "",
      valuelist: [], //已选企业
      optionents: [], //部门对应企业
      bool: true,
      id: "",
      multipleSelection: [], //选中的企业
      delidlist: [], //选中企业的id
      currentPage: 1, //分页
      pageSize: 10,
    };
  },

  methods: {
    // 编辑
    handleUpdate(v) {
      // console.log(v);
      this.id = v.id;
      this.username = v.deptName;
      this.value = "";
      this.valuename = v.leaderId;
      this.valuename2 = v.countyMagistrateId;
      this.dialogVisible = true;
    },
    // 编辑确定
    confirm() {
      var valuenameid = "";
      this.optionsname.map((item, index) => {
        if (this.valuename == item.id) {
          valuenameid = item.nickName;
        }
      });
      var valuenameid2 = "";
      this.optionsname2.map((item, index) => {
        // console.log(item);
        if (this.valuename2 == item.id) {
          valuenameid2 = item.nickName;
        }
      });
      PutsysDeptupdate({
        createTime: "",
        deleteFlag: "",
        deptName: this.username,
        id: this.id,
        leaderId: this.valuename,
        leaderName: valuenameid,
        countyMagistrateId: this.valuename2,
        countyMagistrateName: valuenameid2,
        updateTime: "",
      }).then((res) => {
        if (res.code == 200) {
          this.listall();
          this.dialogVisible = false;
          this.username = "";
          this.id = "";
        }
      });
    },

    // 新建部门
    clickadd() {
      this.username = "";
      this.dialogVisible2 = true;
    },
    // 新建部门确认
    confirm2() {
      PostsysDept({
        createTime: "",
        deleteFlag: "",
        deptName: this.username,
        id: "",
        leaderId: "",
        leaderName: "",
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.listall();
          this.dialogVisible2 = false;
          this.username = "";
        }
      });
    },
    // 部门当下企业
    CompanyByDeptId() {
      getCompanyByDeptId({ id: this.id }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.optionents = res.data;
        }
      });
    },
    // 归属企业
    handleenter(v) {
      // console.log(v);
      this.id = v.id;
      this.CompanyByDeptId();
      this.dialogVisible3 = true;
    },
    // 选择器选中负责人
    // add(v) {
    //   console.log(v);
    // },

    // 添加归属企业
    addseelist() {
      this.valuelist.push(this.value);
      if (this.value != "") {
        PostsaveRelation({
          companyId: this.valuelist,
          deptId: this.id,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "添加企业成功",
              type: "success",
            });
            this.CompanyByDeptId();
            this.valuelist = [];
            this.value = "";
          }
        });
      } else {
        this.$message.error("添加企业不能为空");
      }
    },
    // 删除归属企业
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    delseelist() {
      this.multipleSelection.map((item, index) => {
        this.delidlist.push(item.id);
      });
      PostremoveRelation({
        companyId: this.delidlist,
        deptId: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.CompanyByDeptId();
          this.delidlist = [];
          this.multipleSelection = [];
          this.$message({
            message: "解除企业成功",
            type: "success",
          });
        } else {
          this.delidlist = [];
          this.multipleSelection = [];
        }
      });
    },

    // 删除部门
    handleDelete(v) {
      // console.log(v);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysDept({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.listall();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
    // 查看所有部门
    listall() {
      GetsysDept({
        deptName: "",
        pageNum: 1,
        pageSize: 100000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
    seelist2() {
      GetuserList({
        roleId: 9,
      }).then((res) => {
        // console.log(res,111);
        if (res.code == 200) {
          this.optionsname = res.data;
        }
      });
    },
    seelist3() {
      GetuserList({
        roleId: 2,
      }).then((res) => {
        // console.log(res,222);
        if (res.code == 200) {
          this.optionsname2 = res.data;
        }
      });
    },

    // 企业列表
    seelist() {
      GetumsCompany({
        companyName: "",
        country: "",
        pageNum: 1,
        pageSize: 100000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.listall();
    this.seelist();
    this.seelist2();
    this.seelist3();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>