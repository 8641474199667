<template>
  <div style="width: auto; height: 100%" id="myChart4"></div>
</template>

<script>
import * as echarts from "echarts";
import { GetpieMonth } from "@/request/api";
export default {
  name: "HtglEcharts4",
  data() {
    return {};
  },
  mounted() {
    this.echartsInit();
  },
  methods: {
    echartsInit() {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      GetpieMonth().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$echarts.init(document.getElementById("myChart4")).setOption({
            title: {
              text: "执法预警占比",
              left: "center",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              left: "left",
            },
            series: [
              {
                name: "Access From",
                type: "pie",
                radius: "50%",
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>