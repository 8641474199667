<template>
  <div class="index">
    <!-- 展示指标 -->
    <div class="head">
      <div class="box">
        <div class="box_l">
          <i class="el-icon-s-flag"></i>
        </div>
        <div class="box_r">
          <h2>企业数</h2>
          <div class="zhibiao">{{num.companyCount}}</div>
        </div>
      </div>
      <div class="box">
        <div class="box_l">
          <i class="el-icon-s-flag"></i>
        </div>
        <div class="box_r">
          <h2>执法人数</h2>
          <div class="zhibiao">{{num.personCount}}</div>
        </div>
      </div>
      <div class="box">
        <div class="box_l">
          <i class="el-icon-s-flag"></i>
        </div>
        <div class="box_r">
          <h2>申请数</h2>
          <div class="zhibiao">{{num.lawCount}}</div>
        </div>
      </div>
      <div class="box">
        <div class="box_l">
          <i class="el-icon-s-flag"></i>
        </div>
        <div class="box_r">
          <h2>执法预警</h2>
          <div class="zhibiao">{{num.exceptionCount}}</div>
        </div>
      </div>
    </div>
    <!-- 图表展示 -->
    <div class="echartsbox">
      <div class="conboxs">
        <Echarts5 />
      </div>
      <div class="conbox">
        <Echarts1 />
      </div>
      <div class="conbox">
        <Echarts2 />
      </div>
      <div class="conbox">
        <Echarts3 />
      </div>
      <div class="conbox">
        <Echarts4 />
      </div>
    </div>
    <!-- 表格 -->
    <div class="tablebox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="执法记录数据列表" name="1">
          <Table1 />
        </el-tab-pane>
        <el-tab-pane label="企业上报数据列表" name="2">
          <Table2 />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { GetanalysisdataIndex } from "@/request/api";
import Echarts1 from "../../components/homeecharts/echarts1.vue";
import Echarts2 from "../../components/homeecharts/echarts2.vue";
import Echarts3 from "../../components/homeecharts/echarts3.vue";
import Echarts4 from "../../components/homeecharts/echarts4.vue";
import Echarts5 from "../../components/homeecharts/echarts5.vue";
import Table1 from "../../components/hometable/hometable1.vue";
import Table2 from "../../components/hometable/hometable2.vue";
export default {
  name: "HtglIndex",

  data() {
    return {
      activeName: "1",
      num: "",
    };
  },

  methods: {
    handleClick(tab, event) {},
    // 指标
    zhibiao() {
      GetanalysisdataIndex().then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.num = res.data;
        }
      });
    },
  },

  mounted() {
    this.zhibiao();
  },
  components: {
    Echarts1,
    Echarts2,
    Echarts3,
    Echarts4,
    Echarts5,
    Table1,
    Table2,
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  padding: 8px;
  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .box {
      display: flex;
      width: 23%;
      height: 130px;
      padding: 8px;
      background-color: #fff;
      box-shadow: 0px 0px 5px 2px #eee;
      .box_l {
        width: 50%;
        text-align: center;
        .el-icon-s-flag {
          font-size: 40px;
          line-height: 100px;
          color: #555;
        }
      }
      .box_r {
        h2 {
          font-size: 20px;
          color: #999;
          font-weight: 600;
          line-height: 60px;
        }
        .zhibiao {
          font-size: 25px;
          color: #666;
        }
      }
    }
  }
  .echartsbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .conbox {
      width: 48%;
      height: 400px;
      box-shadow: 0px 0px 5px 2px #eee;
      background-color: #fff;
      margin-bottom: 30px;
      padding: 8px;
    }
    .conboxs {
      width: 100%;
      height: 400px;
      box-shadow: 0px 0px 5px 2px #eee;
      background-color: #fff;
      margin-bottom: 30px;
      padding: 8px;
    }
  }
}
</style>